import { StockIndicatorProps } from 'tirecloud-pattern-library';
import { match } from 'ts-pattern';

const env = import.meta.env ?? process.env;

export const DB_NAME = 'app-version-db';
export const STORE_NAME = 'versions';
export const ACTIVE_VERSION_KEY = 'active-version';
export const PENDING_VERSION_KEY = 'pending-version';

export const APP_VERSION = `${
  env.REACT_APP_VERSION && env.REACT_APP_VERSION.length > 0
    ? env.REACT_APP_VERSION
    : env.REACT_APP_PACKAGE_JSON_VERSION
}${env.REACT_APP_BRANCH ? `-${env.REACT_APP_BRANCH}` : ''}${env.REACT_APP_BUILD_NUMBER ? `#${env.REACT_APP_BUILD_NUMBER}` : ''}`;

export const DEFAULT_DOCUMENT_TITLE = env.REACT_APP_NAME;
export const AUTH_TOKEN_KEY = 'auth_token';
export const SETTING_LANGUAGE_KEY = 'setting_language';

//  5 days
export const REFRESH_TOKEN_THRESHOLD = 5 * 24 * 60 * 60 * 1000;
export const APP_UPDATE_MODAL_REMIND_ME_LATER_KEY = 'app_update_modal_remind_me_later';

export const DEFAULT_ADMIN_PAGE = match(env.REACT_APP_CLIENT_NAME)
  .with('kislev', () => '/admin/users')
  .otherwise(() => '/admin/analytics');

export const SUPPORTED_LANGUAGES =
  env.REACT_APP_CLIENT_NAME === 'kislev'
    ? {
        en: {
          name: 'English',
          shortCode: 'en',
        },
        he: {
          name: 'Hebrew',
          shortCode: 'he',
        },
      }
    : {
        en: {
          name: 'English',
          shortCode: 'en',
        },
        de: {
          name: 'German',
          shortCode: 'de',
        },
      };

export const COOKIE_CONSENT_KEY = 'cookie_consent';
export const ONBOARDING_KEYPREFIX = 'onboarding_';
export const ADMIN_DASHBOARD_SETTING_KEY = 'admin_dashboard_setting';
export const STOCK_LOW_THRESHOLD = 20;
export const CART_PROMO_CODE_KEY = 'cart_promo_code';
export const FAKE_MAX_STOCK = 9999;
export const SHOW_STOCK_COUNT_ON_STATUS: Exclude<StockIndicatorProps['showStockOn'], undefined> = [
  'low',
];

export const PRODUCT_DATA_STALE_TIME = 5 * 60 * 1000;
export const DEFAULT_DATA_STALE_TIME = 10 * 60 * 1000;
export const DEFAULT_LONG_DATA_STALE_TIME = 30 * 60 * 1000;
