import { PRODUCT_DATA_STALE_TIME } from '@/constants';
import { useAxios } from '@/utils/axios';
import { useAdminSettingQuery } from '@/utils/misc';
import { css, cx } from '@emotion/css';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { App, AutoComplete, Grid, Input, Tag } from 'antd';
import { ComponentProps, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'tirecloud-pattern-library/dist/components/button/Button';
import { StringParam, useQueryParam } from 'use-query-params';
import breakpoints from 'tirecloud-pattern-library/dist/components/ant-app/breakpoints';

function Search({ isMobileSearch = false }: { isMobileSearch?: boolean }) {
  const { t } = useTranslation();
  const { data: adminSettings } = useAdminSettingQuery();
  const [searchQuery] = useQueryParam('q', StringParam);
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null);
  const navigate = useNavigate();
  const [searchHistory, setSearchHistory] = useState<string[]>([]);
  const [suggestionDropdownOpen, setSuggestionDropdownOpen] = useState(false);
  const breakpoint = Grid.useBreakpoint();

  // const [autocompleteOptions, setAutoCompleteOptions] = useState<
  //   Array<{
  //     value: string;
  //   }>
  // >([]);
  const axios = useAxios();
  const { notification } = App.useApp();

  const { data: matchCodes, isError } = useQuery<string[]>({
    queryKey: ['matchCodes'],
    queryFn: async () => {
      return axios
        .get(`${import.meta.env.REACT_APP_API_URL}/api/v1/tire-sizes/match-codes`)
        .then((res) => res.data)
        .then((res) => {
          return res.matchCodes;
        })
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
    staleTime: PRODUCT_DATA_STALE_TIME,
    enabled: adminSettings?.featureFlags.component.productSearch.enableSuggestion,
  });

  useEffect(() => {
    if (isError) {
      notification.error({
        key: 'APP004',
        message: (
          <Trans
            i18nKey={'component.search.matchCodeFetchError'}
            components={{
              ErrorCode: <Tag className="tw-mr-[length:var(--spacing-1)]" color="error" />,
            }}
          />
        ),
        duration: 0,
      });
    }
  }, [isError, notification]);

  useEffect(() => {
    if (!searchQuery) {
      setSearchKeyword(null);
    }
  }, [searchQuery]);

  // const updateSuggestions = useCallback(
  //   (value: string) => {
  //     if (!matchCodes) return;
  //     let suggestions: typeof autocompleteOptions = [];

  //     if (value.length >= 1) {
  //       const filterData = matchCodes.filter((item) => {
  //         return (
  //           item.toLowerCase().includes(value.toLowerCase()) ||
  //           item
  //             .replace(/[A-Z]/g, '')
  //             .toLowerCase()
  //             .includes(value.replace(/[A-Z]/g, '').toLowerCase())
  //         );
  //       });
  //       const sortData = filterData;
  //       const match = sortData;

  //       const filtered = sortData.filter((item, index) => {
  //         return !match.includes(item, index + 1);
  //       });
  //       suggestions = filtered.map((item) => {
  //         return {
  //           value: item,
  //         };
  //       });
  //     }
  //     // setAutoCompleteOptions(suggestions.slice(0, 5));
  //   },
  //   [matchCodes]
  // );

  const autocompleteOptions = useMemo(() => {
    if (!matchCodes) return [];

    const value = searchKeyword ?? '';
    let suggestions: Array<{
      value: string;
    }> = [];

    if (value.length >= 1) {
      const filterData = matchCodes.filter((item) => {
        return (
          item.toLowerCase().includes(value.toLowerCase()) ||
          item
            .replace(/[A-Z]/g, '')
            .toLowerCase()
            .includes(value.replace(/[A-Z]/g, '').toLowerCase())
        );
      });
      const sortData = filterData;
      const match = sortData;

      const filtered = sortData.filter((item, index) => {
        return !match.includes(item, index + 1);
      });
      suggestions = filtered.map((item) => {
        return {
          value: item,
        };
      });
    }

    return suggestions;
  }, [matchCodes, searchKeyword]);

  const applySearch = useCallback(
    (searchStr: string) => {
      navigate(`/products?q=${searchStr}`);
      setSearchHistory((prev) => {
        const newHistory = prev.filter((item) => item !== searchStr);
        newHistory.unshift(searchStr);
        return newHistory;
      });
    },
    [navigate]
  );

  const location = useLocation();

  const autocompOption = useMemo(() => {
    return [
      {
        label: t('component.search.suggestions'),
        options:
          autocompleteOptions.length > 0
            ? autocompleteOptions
            : [{ value: t('component.search.noSuggestion'), disabled: true }],
      },
    ];
  }, [
    t,
    autocompleteOptions,
    // searchHistory
  ]);

  const mobileSearchClassName = useMemo(() => {
    if (location.pathname.startsWith('/brand') || location.pathname === '/about') {
      return 'tw-hidden';
    }
    return undefined;
  }, [location.pathname]);

  const ref: ComponentProps<typeof AutoComplete>['ref'] = useRef(null);

  return (
    <div
      className={
        isMobileSearch ? mobileSearchClassName : 'tw-h-[60px] tw-max-w-[400px] !tw-flex-grow'
      }
      {...(isMobileSearch ? { id: 'main-mobile-searchbox' } : { id: 'main-header-searchbox' })}
    >
      <AutoComplete
        ref={ref}
        className="tw-w-full"
        options={
          adminSettings?.featureFlags.component.productSearch.enableSuggestion ? autocompOption : []
        }
        onSearch={
          adminSettings?.featureFlags.component.productSearch.enableSuggestion
            ? (value) => {
                setSearchKeyword(value);
              }
            : undefined
        }
        onSelect={
          adminSettings?.featureFlags.component.productSearch.enableSuggestion
            ? (searchStr) => {
                setSearchKeyword(searchStr);
                applySearch(searchStr);
                setSuggestionDropdownOpen(false);
                ref.current?.nativeElement.querySelector('input')?.blur();
                if (window._paq) {
                  window._paq.push(['trackEvent', 'Search', 'Click Search Suggestion', searchStr]);
                }
              }
            : undefined
        }
        popupClassName="search-dropdown"
        value={searchKeyword === null ? (searchQuery ?? '') : searchKeyword}
        listHeight={130}
        open={suggestionDropdownOpen}
        onDropdownVisibleChange={(visible) => setSuggestionDropdownOpen(visible)}
      >
        <div className={isMobileSearch ? 'mobile-search-sm' : 'top-search-box'}>
          <div
            className={css`
              padding: var(--spacing-4);
              border-radius: 200px;
              border: 1px solid var(--shade-200);
              color: var(--content-text-color);
              background-color: var(--white-color);
              padding-right: 56px;

              @media (min-width: ${breakpoints.sm}px) {
                border-color: var(--shade-800);
                background-color: var(--shade-900);
              }

              & > input {
                padding: 0 !important;
                border: none !important;
              }

              & > input:focus,
              & > input:hover {
                box-shadow: none !important;
              }

              [dir='rtl'] & {
                padding-left: 56px;
                padding-right: var(--spacing-4);
              }
            `}
            onClick={(e) => {
              // move the cursor to the end of the input
              const target = e.target;
              if (!(target instanceof HTMLElement)) return;

              if (target instanceof HTMLInputElement) {
                // if the input is not focused, focus it
                if (document.activeElement !== target || !breakpoint.sm) {
                  target.focus();
                  target.setSelectionRange(target.value.length, target.value.length);
                }
              } else {
                const input = target.querySelector('input');

                // if the input is not focused, focus it
                if (document.activeElement !== input) {
                  input?.focus();
                  input?.setSelectionRange(input.value.length, input.value.length);
                }
              }
            }}
          >
            <Input
              type="search"
              placeholder={t('component.search.placeholder')}
              rootClassName={isMobileSearch ? undefined : '!tw-placeholder-[var(--shade-200)]'}
              value={searchKeyword === null ? (searchQuery ?? '') : searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              onPressEnter={() => {
                applySearch(searchKeyword ?? '');
                setSuggestionDropdownOpen(false);
                ref.current?.nativeElement.querySelector('input')?.blur();
              }}
              data-test-id="header-search-input"
            />
          </div>
          <Button
            type="submit"
            disabled={!searchKeyword}
            className={cx(
              'btn-icon submit-search',
              (searchKeyword?.length ?? 0) > 0 ? '!tw-text-[var(--t-blue)]' : undefined,
              css`
                &:active {
                  transform: translateY(-50%) !important;
                }
              `
            )}
            onClick={() => {
              applySearch(searchKeyword ?? '');
              setSuggestionDropdownOpen(false);
              ref.current?.nativeElement.querySelector('input')?.blur();
            }}
          >
            {t('component.search.searchBtn')}
          </Button>
        </div>
      </AutoComplete>
    </div>
  );
}

export default Search;
