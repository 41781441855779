import { useAdminSideMenu } from '@/app/admin/sidemenu/get-sidemenu';
import { useAppSelector } from '@/stores';
import { CreditCardOutlined, FileOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconGear } from 'tirecloud-pattern-library/dist/components/Icons/IconGear';
import { match } from 'ts-pattern';

export function useUserSideMenu(firstChildBorderTop: boolean = false) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const adminSideMenu = useAdminSideMenu(firstChildBorderTop);

  return useMemo<
    Array<{
      key: string;
      label: string | JSX.Element;
      icon: JSX.Element;
      className: string;
      onClick: () => void;
    }>
  >(() => {
    return match(user?.__role)
      .with('ROLE_WEBSHOP_USER', () => [
        {
          key: '/user/profile',
          label: t('component.header.userMenu.profile'),
          icon: <UserOutlined />,
          className: classNames(
            'tw-border-0 tw-border-b tw-border-solid tw-border-[rgba(224,224,224,0.45)] tw-py-[length:var(--spacing-6)]',
            firstChildBorderTop ? 'tw-border-t' : ''
          ),
          onClick: () => {
            navigate('/user/profile');
          },
        },
        {
          key: '/user/accounting',
          label: t('component.header.userMenu.accounting'),
          icon: <CreditCardOutlined />,
          className:
            'tw-border-0 tw-border-b tw-border-solid tw-border-[rgba(224,224,224,0.45)] tw-py-[length:var(--spacing-6)]',
          onClick: () => {
            navigate('/user/accounting');
          },
        },
        {
          key: '/user/security',
          label: t('component.header.userMenu.security'),
          icon: <LockOutlined />,
          className:
            'tw-border-0 tw-border-b tw-border-solid tw-border-[rgba(224,224,224,0.45)] tw-py-[length:var(--spacing-6)]',
          onClick: () => {
            navigate('/user/security');
          },
        },
        {
          key: '/user/notification-settings',
          label: t('component.header.userMenu.notificationSettings'),
          icon: (
            <span>
              <IconGear size={20} />
            </span>
          ),
          className:
            'tw-border-0 tw-border-b tw-border-solid tw-border-[rgba(224,224,224,0.45)] tw-py-[length:var(--spacing-6)]',
          onClick: () => {
            navigate('/user/notification-settings');
          },
        },
        {
          key: '/user/complaints',
          label: t('component.header.userMenu.complaints'),
          icon: <FileOutlined />,
          className:
            'tw-border-0 tw-border-b tw-border-solid tw-border-[rgba(224,224,224,0.45)] tw-py-[length:var(--spacing-6)]',
          onClick: () => {
            navigate('/user/complaints');
          },
        },
      ])
      .with('ROLE_ADMIN', () => [
        {
          key: '/user/profile',
          label: t('component.header.userMenu.profile'),
          icon: <UserOutlined />,
          className: classNames(
            'tw-border-0 tw-border-b tw-border-solid tw-border-[rgba(224,224,224,0.45)] tw-py-[length:var(--spacing-6)]',
            firstChildBorderTop ? 'tw-border-t' : ''
          ),
          onClick: () => {
            navigate('/user/profile');
          },
        },
        ...adminSideMenu,
      ])
      .otherwise(() => []);
  }, [user?.__role, t, firstChildBorderTop, navigate, adminSideMenu]);
}
