import { ProductBundle, ProductBundleItem, Tire, TireDot, TireSize } from '@/app/products/types';
import { ShippingAddress } from '@/components/checkout-modal/common';
import { customerSpecificRouteMapping } from '@/customer-specific-configs/customer-specific-settings';
import { useAppSelector } from '@/stores';
import * as Sentry from '@sentry/react';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import { App, Tag } from 'antd';
import { createElement, useEffect, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAxios } from './axios';
import { useAdminSettingQuery } from './misc';
import { CartItemResponse, CartResponse, getTireDotPrice } from './product';

export function useCartQuery(options: UseQueryOptions<Array<CartResponse>> = {}) {
  const { t } = useTranslation();
  const { data: adminSettings } = useAdminSettingQuery();
  const axios = useAxios();

  return useQuery({
    queryKey: ['carts'],
    queryFn: async () => {
      return axios
        .get(`${import.meta.env.REACT_APP_API_URL}/api/v1/cart`)
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          return Promise.resolve(
            data.cart.map(
              (
                cart: Omit<CartResponse, 'cartItem'> & {
                  cartItem: Array<
                    Omit<CartItemResponse, 'tireSize'> & {
                      tireSize: Omit<TireSize, 'tire'> & {
                        tire: Tire[];
                      };
                      productBundleItem:
                        | (Omit<CartItemResponse['productBundleItem'], 'tireSize'> & {
                            tireSize: Omit<TireSize, 'tire'> & {
                              tire: Tire[];
                            };
                          })
                        | null;
                    }
                  >;
                }
              ) => {
                return {
                  ...cart,
                  cartItem: cart.cartItem.map((cartItem) => {
                    return {
                      ...cartItem,
                      tireSize: {
                        ...cartItem.tireSize,
                        tire: {
                          ...cartItem.tireSize.tire[0],
                        },
                        tireDot: adminSettings?.featureFlags.page.product.enableTireDot
                          ? cartItem.tireSize.tireDot
                          : [
                              {
                                id: cartItem.tireSize.id,
                                dotCode: null,
                                yearOfManufacture: new Date().getFullYear(),
                                isDefault: true,
                                stock: cartItem.tireSize.stock!,
                                price: cartItem.tireSize.price!,
                                specialPrice: cartItem.tireSize.specialPrice!,
                              } satisfies TireSize['tireDot'][number],
                            ],
                      },
                      tireDot: adminSettings?.featureFlags.page.product.enableTireDot
                        ? cartItem.tireDot
                        : {
                            id: cartItem.tireSize.id,
                            dotCode: null,
                            yearOfManufacture: new Date().getFullYear(),
                            isDefault: true,
                            stock: cartItem.tireSize.stock!,
                            price: cartItem.tireSize.price!,
                            specialPrice: cartItem.tireSize.specialPrice!,
                          },
                      productBundleItem: cartItem.productBundleItem
                        ? {
                            ...cartItem.productBundleItem,
                            tireSize: {
                              ...cartItem.productBundleItem.tireSize,
                              tire: {
                                ...cartItem.productBundleItem.tireSize.tire[0],
                              },
                              tireDot:
                                adminSettings?.featureFlags.page.product.enableTireDot === true
                                  ? cartItem.productBundleItem.tireSize.tireDot
                                  : [
                                      {
                                        id: cartItem.tireSize.id,
                                        dotCode: null,
                                        yearOfManufacture: new Date().getFullYear(),
                                        isDefault: true,
                                        stock: 0,
                                        price: cartItem.productBundleItem.qPrice,
                                        specialPrice: [],
                                      } satisfies TireSize['tireDot'][number],
                                    ],
                            },
                          }
                        : null,
                    };
                  }),
                };
              }
            ) as CartResponse[]
          );
        })
        .catch((err) => {
          Sentry.captureException(err);
          return Promise.reject(t('page.cart.feedback.cartFetchErrorTitle'));
        });
    },
    staleTime: 0,
    ...options,
  });
}

export interface ProductBundleEntry {
  isBundle: true;
  bundle: Pick<ProductBundle, 'id' | 'modelName'> | null;
  items: CartItemResponse[];
  key: string;
}

export function useCartDisplayEntries() {
  const { user } = useAppSelector((state) => state.auth);
  const { data: userCarts } = useCartQuery({
    enabled: user?.__role === 'ROLE_WEBSHOP_USER',
  });

  return useMemo(() => {
    const cartItems = userCarts?.[0]?.cartItem ?? [];
    const entries: Array<
      ({ isBundle: false; key: string } & CartItemResponse) | ProductBundleEntry
    > = [];

    for (const item of cartItems) {
      if (item.productBundleItem) {
        const previousIdx = entries.findIndex((entry) => {
          return (
            entry.isBundle &&
            entry.bundle?.id === item.productBundleItem?.productBundle.id &&
            entry.items.every((i) => i.id !== item.id)
          );
        });

        if (previousIdx >= 0) {
          (entries[previousIdx] as ProductBundleEntry).items.push(item);
        } else {
          entries.push({
            isBundle: true,
            bundle: item.productBundleItem?.productBundle,
            items: [item],
            key: item.productBundleItem?.productBundle.id.toString(),
          });
        }
      } else {
        entries.push({ ...item, isBundle: false, key: item.id.toString() });
      }
    }

    return entries;
  }, [userCarts]);
}

export type CartPayload = Omit<CartResponse, 'cartItem'> & {
  cartItem: Array<{
    quantity: number;
    tireSize: number;
    tireDot?: number;
    specialPrice?: number | null;
    productBundleItem?: number;
  }>;
};

export function useCreateCartMutation() {
  const client = useQueryClient();
  const axios = useAxios();

  return useMutation({
    mutationKey: ['create', 'cart'],
    mutationFn: async (cart: Omit<CartPayload, 'id'>) => {
      return axios.post(`${import.meta.env.REACT_APP_API_URL}/api/v1/cart`, cart).catch((error) => {
        Sentry.captureException(error);
        return Promise.reject(error);
      });
    },
    onSuccess: () => {
      client.invalidateQueries(['carts']);
    },
  });
}

export function useUpdateCartMutation() {
  const client = useQueryClient();
  const axios = useAxios();

  return useMutation({
    mutationKey: ['carts'],
    mutationFn: async (cart: CartPayload) => {
      const { id, ...rest } = cart;
      return axios
        .put(`${import.meta.env.REACT_APP_API_URL}/api/v1/cart/${id}`, rest)
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
    onSuccess: () => {
      client.invalidateQueries(['carts']);
    },
  });
}

export function useDeleteCartItemMutation() {
  const client = useQueryClient();
  const axios = useAxios();

  return useMutation({
    mutationKey: ['carts'],
    mutationFn: async (itemId: number) => {
      return axios
        .delete(`${import.meta.env.REACT_APP_API_URL}/api/v1/cart-item/${itemId}`)
        .catch((error) => {
          Sentry.captureException(error);
          return Promise.reject(error);
        });
    },
    onSuccess() {
      client.invalidateQueries(['carts']);
    },
  });
}

export interface OrderPayload {
  orderDate: string; // DD-MM-YYYY HH:mm
  deliveryOption?: string | null;
  estimatedDelivery: string | null;
  totalQuantity: number;
  isCancelled: boolean;
  isReservedStock: boolean;
  voucher?: string;
  voucherPromotion?: number;
  orderStatus: {
    status: 'Placed';
    statusDateTime: string; // DD-MM-YYYY HH:mm:ss
  };
  orderItem: Array<{
    tireSize: number;
    tireDot?: number;
    yearOfManufacture?: number;
    additionalQuantity: number;
    discountAmount: number;
    quantity: number;
    price: number;
    totalPrice: number;
    totalPriceAfterDiscount: number;
  }>;
  shippingAddress?:
    | {
        id: number;
      }
    | (Omit<ShippingAddress, 'id' | 'zipCode'> & {
        zipcode?: string | null;
      });
  invoice: {
    discountAmount: number;
    invoiceType: 'Billable';
    isOpenInvoice: boolean;
    paymentType: 'Credit Check';
    shippingCharge: number;
    taxAmount: number;
    totalExcludeTax: number;
    totalIncludeTax: number;
  };
}

export type PartialProductBundleDataForCartAction = Pick<
  ProductBundle,
  'id' | 'modelName' | 'isBundle'
> & {
  productBundleItem: Array<
    Pick<
      ProductBundleItem,
      'id' | 'quantity' | 'qPrice' | 'price' | 'tireSize' | 'yearOfManufacture'
    >
  >;
};

export function useAddToCart(refetchCart = true) {
  const { data: adminSettings } = useAdminSettingQuery();
  const cartQuery = useCartQuery({
    enabled: refetchCart,
  });
  const updateCartMutation = useUpdateCartMutation();
  const createCartMutation = useCreateCartMutation();
  const { t } = useTranslation();
  const { notification: notiAPI } = App.useApp();

  useEffect(() => {
    if (cartQuery.isError) {
      notiAPI.error({
        key: 'CRT010',
        message: createElement(Trans, {
          i18nKey: 'page.cart.feedback.failedToGetCart',
          components: {
            ErrorCode: createElement(Tag, { className: 'tw-mr-[length:var(--spacing-1)]', color: 'error' }),
          },
        }),
        duration: 0,
      });
    }
  }, [cartQuery.isError, notiAPI]);

  return {
    cart: cartQuery.data,
    isFetchingCart: cartQuery.isFetching,
    addToCart(
      tireSizeOrBundle: TireSize | PartialProductBundleDataForCartAction,
      count: number,
      selectedDotId?: number,
      isUpdating = false
    ) {
      const userCarts = cartQuery.data;
      // cart API call must be successful before clicking the add to cart button
      if (userCarts === undefined) return;

      if (window._paq) {
        window._paq.push(['clearEcommerceCart']);
      }

      const newData: Omit<CartPayload, 'id'> = {
        totalAmount: 0,
        cartItem: [],
      };
      const oldCartItems = userCarts[0]?.cartItem ?? [];

      if (tireSizeOrBundle.isBundle) {
        for (const item of tireSizeOrBundle.productBundleItem) {
          newData.cartItem.push({
            quantity: item.quantity * count,
            productBundleItem: item.id,
            tireSize: item.tireSize.id,
            tireDot: adminSettings?.featureFlags.page.product.enableTireDot
              ? item.tireSize.tireDot.find(
                  (dot) => dot.yearOfManufacture === item.yearOfManufacture
                )?.id
              : undefined,
          });
        }
      } else {
        newData.cartItem.push({
          quantity: count,
          tireSize: tireSizeOrBundle.id,
          tireDot: adminSettings?.featureFlags.page.product.enableTireDot
            ? selectedDotId
            : undefined,
        });
      }

      const cartPayload: Omit<CartPayload, 'id'> = {
        cartItem: oldCartItems.map((item) => {
          return {
            id: item.id,
            quantity: item.quantity,
            tireSize: item.tireSize.id,
            tireDot: item.tireDot ? item.tireDot.id : undefined,
            specialPrice: item.specialPrice ? item.specialPrice.id : undefined,
            productBundleItem: item.productBundleItem ? item.productBundleItem.id : undefined,
          } as CartPayload['cartItem'][0];
        }),
        totalAmount: oldCartItems.length === 0 ? 0 : (userCarts[0]?.totalAmount ?? 0),
      };

      for (const newCartItem of newData.cartItem) {
        const isCurrentItemInCart = oldCartItems.findIndex((item) => {
          return newCartItem.productBundleItem
            ? item.productBundleItem &&
                item.productBundleItem.id === newCartItem.productBundleItem &&
                item.tireSize.id === newCartItem.tireSize &&
                (adminSettings?.featureFlags.page.product.enableTireDot
                  ? item.tireDot!.id === newCartItem.tireDot
                  : true)
            : !item.productBundleItem &&
                item.tireSize.id === newCartItem.tireSize &&
                (adminSettings?.featureFlags.page.product.enableTireDot
                  ? item.tireDot!.id === newCartItem.tireDot
                  : true);
        });

        if (isCurrentItemInCart !== -1) {
          const oldCartItem = oldCartItems[isCurrentItemInCart];
          const oldCartItemQuantity = cartPayload.cartItem[isCurrentItemInCart].quantity;
          cartPayload.cartItem[isCurrentItemInCart].quantity += newCartItem.quantity;

          const priceToUse = oldCartItem.productBundleItem
            ? (() => {
                const bundleItem: Pick<
                  ProductBundleItem,
                  'id' | 'tireSize' | 'quantity' | 'price' | 'qPrice'
                > & {
                  productBundle: Pick<ProductBundle, 'id' | 'modelName'>;
                } = oldCartItem.productBundleItem;
                const bundleCount = newCartItem.quantity / bundleItem.quantity;

                if (window._paq) {
                  const productName = `(Product Bundle: ${bundleItem.productBundle.modelName}) ${bundleItem.tireSize.tire.brand.brandName} ${bundleItem.tireSize.tire.title} [${bundleItem.tireSize.tire.productCode}]`;
                  window._paq.push([
                    'addEcommerceItem',
                    bundleItem.tireSize.tire.productCode,
                    productName,
                    'Tire',
                    bundleItem.price,
                    newCartItem.quantity,
                  ]);
                }

                return {
                  price: bundleItem.qPrice * bundleCount,
                };
              })()
            : (() => {
                const p = getTireDotPrice(
                  adminSettings?.featureFlags.page.product.enableTireDot
                    ? oldCartItem.tireSize.tireDot
                    : ([
                        {
                          id: tireSizeOrBundle.id,
                          dotCode: null,
                          yearOfManufacture: new Date().getFullYear(),
                          isDefault: true,
                          stock: oldCartItem.tireSize.stock!,
                          price: oldCartItem.tireSize.price!,
                          specialPrice: oldCartItem.tireSize.specialPrice!,
                        },
                      ] as TireDot[]),
                  // because tireSize is included in fake tireDot[] if tireDot is not enabled but Cart response does not have tireDot
                  adminSettings?.featureFlags.page.product.enableTireDot
                    ? oldCartItem.tireDot!.id
                    : oldCartItem.tireSize.id,
                  // if we are decreasing the quantity, the price should be calculated based on the old quantity
                  // as special price was based on the old quantity
                  newCartItem.quantity < 0
                    ? oldCartItemQuantity
                    : cartPayload.cartItem[isCurrentItemInCart].quantity
                );

                if (window._paq) {
                  const yom = `${adminSettings?.featureFlags.page.product.enableTireDot ? ` (${oldCartItem.tireDot.yearOfManufacture})` : ''}`;
                  const productName = `${oldCartItem.tireSize.tire.brand.brandName} ${oldCartItem.tireSize.tire.title}${yom} [${oldCartItem.tireSize.tire.productCode}]`;
                  window._paq.push([
                    'addEcommerceItem',
                    oldCartItem.tireSize.tire.productCode,
                    productName,
                    'Tire',
                    p.price,
                    newCartItem.quantity,
                  ]);
                }

                return {
                  ...p,
                  price: p.price * newCartItem.quantity,
                };
              })();

          cartPayload.totalAmount += priceToUse.price;
        } else {
          const priceToUse = tireSizeOrBundle.isBundle
            ? (() => {
                const bundleItem = tireSizeOrBundle.productBundleItem.find(
                  (item) => item.id === newCartItem.productBundleItem
                );
                if (!bundleItem) {
                  return {
                    price: 0,
                  };
                }

                if (window._paq) {
                  const productName = `(Product Bundle: ${tireSizeOrBundle.modelName}) ${bundleItem.tireSize.tire.brand.brandName} ${bundleItem.tireSize.tire.title} [${bundleItem.tireSize.tire.productCode}]`;
                  window._paq.push([
                    'addEcommerceItem',
                    bundleItem.tireSize.tire.productCode,
                    productName,
                    'Tire',
                    bundleItem.price,
                    newCartItem.quantity,
                  ]);
                }

                const bundleCount = newCartItem.quantity / bundleItem.quantity;
                return {
                  price: bundleItem.qPrice * bundleCount,
                };
              })()
            : (() => {
                const p = getTireDotPrice(
                  adminSettings?.featureFlags.page.product.enableTireDot
                    ? tireSizeOrBundle.tireDot
                    : ([
                        {
                          id: tireSizeOrBundle.id,
                          dotCode: null,
                          yearOfManufacture: new Date().getFullYear(),
                          isDefault: true,
                          stock: tireSizeOrBundle.stock!,
                          price: tireSizeOrBundle.price!,
                          specialPrice: tireSizeOrBundle.specialPrice!,
                        },
                      ] as TireDot[]),
                  // because tireSize is included in fake tireDot[] if tireDot is not enabled but Cart response does not have tireDot
                  adminSettings?.featureFlags.page.product.enableTireDot
                    ? newCartItem.tireDot!
                    : newCartItem.tireSize,
                  newCartItem.quantity
                );

                if (window._paq) {
                  const selectedTireDot = (tireSizeOrBundle.tireDot ?? []).find(
                    (dot) => dot.id === newCartItem.tireDot
                  );

                  const yom = `${adminSettings?.featureFlags.page.product.enableTireDot && selectedTireDot ? ` (${selectedTireDot.yearOfManufacture})` : ''}`;
                  const productName = `${tireSizeOrBundle.tire.brand.brandName} ${tireSizeOrBundle.tire.title}${yom} [${tireSizeOrBundle.tire.productCode}]`;
                  window._paq.push([
                    'addEcommerceItem',
                    tireSizeOrBundle.tire.productCode,
                    productName,
                    'Tire',
                    p.price,
                    count,
                  ]);
                }

                return {
                  ...p,
                  price: p.price * newCartItem.quantity,
                };
              })();
          cartPayload.cartItem.push(newCartItem);
          cartPayload.totalAmount += priceToUse.price;
        }
      }

      if (window._paq) {
        for (const oldCartItem of oldCartItems) {
          // log to matomo if oldCartItem is not in the new cart
          const isCurrentItemInCart = newData.cartItem.findIndex((item) => {
            return oldCartItem.productBundleItem
              ? item.productBundleItem &&
                  item.productBundleItem === oldCartItem.productBundleItem.id &&
                  item.tireSize === oldCartItem.tireSize.id &&
                  (adminSettings?.featureFlags.page.product.enableTireDot
                    ? item.tireDot === oldCartItem.tireDot?.id
                    : true)
              : !item.productBundleItem &&
                  item.tireSize === oldCartItem.tireSize.id &&
                  (adminSettings?.featureFlags.page.product.enableTireDot
                    ? item.tireDot === oldCartItem.tireDot?.id
                    : true);
          });

          if (isCurrentItemInCart === -1) {
            if (oldCartItem.productBundleItem) {
              const bundleItem = oldCartItem.productBundleItem;

              const productName = `(Product Bundle: ${bundleItem.productBundle.modelName}) ${bundleItem.tireSize.tire.brand.brandName} ${bundleItem.tireSize.tire.title} [${bundleItem.tireSize.tire.productCode}]`;
              window._paq.push([
                'addEcommerceItem',
                bundleItem.tireSize.tire.productCode,
                productName,
                'Tire',
                bundleItem.price,
                oldCartItem.quantity,
              ]);
            } else {
              const p = getTireDotPrice(
                adminSettings?.featureFlags.page.product.enableTireDot
                  ? oldCartItem.tireSize.tireDot
                  : ([
                      {
                        id: oldCartItem.tireSize.id,
                        dotCode: null,
                        yearOfManufacture: new Date().getFullYear(),
                        isDefault: true,
                        stock: oldCartItem.tireSize.stock!,
                        price: oldCartItem.tireSize.price!,
                        specialPrice: oldCartItem.tireSize.specialPrice!,
                      },
                    ] as TireDot[]),
                // because tireSize is included in fake tireDot[] if tireDot is not enabled but Cart response does not have tireDot
                adminSettings?.featureFlags.page.product.enableTireDot
                  ? oldCartItem.tireDot!.id
                  : oldCartItem.tireSize.id,
                oldCartItem.quantity
              );

              const yom = `${adminSettings?.featureFlags.page.product.enableTireDot ? ` (${oldCartItem.tireDot.yearOfManufacture})` : ''}`;
              const productName = `${oldCartItem.tireSize.tire.brand.brandName} ${oldCartItem.tireSize.tire.title}${yom} [${oldCartItem.tireSize.tire.productCode}]`;

              window._paq.push([
                'addEcommerceItem',
                oldCartItem.tireSize.tire.productCode,
                productName,
                'Tire',
                p.price,
                oldCartItem.quantity,
              ]);
            }
          }
        }
      }

      if (userCarts.length > 0) {
        updateCartMutation.mutate(
          { ...cartPayload, id: userCarts[0].id },
          {
            onSuccess() {
              if (!isUpdating) {
                notiAPI.success({
                  message: t('component.addToCartBtn.itemAdded'),
                });
              }
              if (window._paq) {
                window._paq.push(['trackEcommerceCartUpdate', cartPayload.totalAmount]);
              }
            },
            onError() {
              notiAPI.error({
                // key: 'CRT005',
                message: createElement(Trans, {
                  i18nKey: isUpdating
                    ? 'page.cart.feedback.failedToUpdateCart'
                    : 'page.cart.feedback.failedToAddItemToCart',
                  components: {
                    ErrorCode: createElement(Tag, { className: 'tw-mr-[length:var(--spacing-1)]', color: "error" }),
                  },
                }),
                duration: 0,
              });
            },
          }
        );
      } else {
        createCartMutation.mutate(cartPayload, {
          onSuccess() {
            notiAPI.success({
              message: t('component.addToCartBtn.itemAdded'),
            });
            if (window._paq) {
              window._paq.push(['trackEcommerceCartUpdate', cartPayload.totalAmount]);
            }
          },
          onError() {
            notiAPI.error({
              // key: 'CRT005',
              message: createElement(Trans, {
                i18nKey: 'page.cart.feedback.failedToAddItemToCart',
                components: {
                  ErrorCode: createElement(Tag, {
                    className: 'tw-mr-[length:var(--spacing-1)]',
                    color: 'error',
                  }),
                },
              }),
              duration: 0,
            });
          },
        });
      }
    },
  };
}

export function useGetRTAvailableStocksQuery(tireSizeIds: number[]) {
  const { data: adminSettings } = useAdminSettingQuery();
  const axios = useAxios();

  return useQuery<
    Array<{
      id: string;
      tire: { id: string; isForSale: boolean; brand: { id: string } };
      tireDot: Array<{ id: string; yearOfManufacture: number; stock: number }>;
    }>
  >({
    queryKey: ['rtAvailableStocks', ...tireSizeIds],
    queryFn: async () => {
      return axios
        .patch(
          `${customerSpecificRouteMapping[`${import.meta.env.REACT_APP_API_URL}/api/v1/tire-sizes/stock`]}?ids=${tireSizeIds.join(',')}`
        )
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          return data.tireSizes.map(
            (tireSize: {
              id: string;
              tire: Array<{ id: string; isForSale: boolean; brand: { id: string } }>;
              tireDot: Array<{ id: string; yearOfManufacture: number; stock: number }>;
              stock?: number;
            }) => {
              return {
                ...tireSize,
                tire: {
                  ...tireSize.tire[0],
                },
                ...(adminSettings?.featureFlags.page.product.enableTireDot
                  ? {}
                  : {
                      tireDot: [
                        {
                          id: tireSize.id,
                          yearOfManufacture: new Date().getFullYear(),
                          stock: tireSize.stock!,
                        },
                      ],
                    }),
              };
            }
          );
        });
    },
    enabled: tireSizeIds.length > 0,
    staleTime: 0,
  });
}
