import { Link } from 'react-router-dom';
import { Button } from 'tirecloud-pattern-library/dist/components/button/Button';
import css from './403.module.scss';
import { useTranslation } from 'react-i18next';

function Error403Page() {
  const { t } = useTranslation();

  return (
    <main className="page-content">
      <div className={css['access-denied']}>
        <div className={css.inner}>
          <h1 dangerouslySetInnerHTML={{ __html: t('page.errors.error403.code').toString() }}></h1>
          <h3>{t('page.errors.error403.description')}</h3>
          <Link to={'/'}>
            <Button>{t('page.errors.error403.goBackHome')}</Button>
          </Link>
        </div>
      </div>
    </main>
  );
}

export default Error403Page;
