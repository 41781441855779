import { useUserSideMenu } from '@/app/user/sidemenu/get-sidemenu';
import { AUTH_TOKEN_KEY, DEFAULT_ADMIN_PAGE, SUPPORTED_LANGUAGES } from '@/constants';
import { useAppDispatch, useAppSelector } from '@/stores';
import authStateSlice from '@/stores/states/auth';
import { useCartDisplayEntries } from '@/utils/cart';
import { useAdminSettingQuery } from '@/utils/misc';
import { useMarkNotificationAsReadMutation, useNotificationQuery } from '@/utils/notification';
import { PhoneOutlined } from '@ant-design/icons';
import { App, Collapse, Dropdown, Grid, MenuProps, Select, Space, Tag, Tooltip } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { Button } from 'tirecloud-pattern-library/dist/components/button/Button';
import { IconArrowDown } from 'tirecloud-pattern-library/dist/components/Icons/IconArrowDown';
import { IconBell } from 'tirecloud-pattern-library/dist/components/Icons/IconBell';
import { IconBox } from 'tirecloud-pattern-library/dist/components/Icons/IconBox';
import { IconCalandar } from 'tirecloud-pattern-library/dist/components/Icons/IconCalandar';
import { IconCart } from 'tirecloud-pattern-library/dist/components/Icons/IconCart';
import { IconGear } from 'tirecloud-pattern-library/dist/components/Icons/IconGear';
import { IconGrid } from 'tirecloud-pattern-library/dist/components/Icons/IconGrid';
import { IconHamberger } from 'tirecloud-pattern-library/dist/components/Icons/IconHamberger';
import { IconLogout } from 'tirecloud-pattern-library/dist/components/Icons/IconLogout';
import { IconPlus } from 'tirecloud-pattern-library/dist/components/Icons/IconPlus';
import { IconExclamation } from 'tirecloud-pattern-library/dist/components/Icons/Icons';
import { IconUser } from 'tirecloud-pattern-library/dist/components/Icons/IconUser';
import { Modal } from 'tirecloud-pattern-library/dist/components/modal/Modal';
import { match } from 'ts-pattern';
import { StringParam, useQueryParam } from 'use-query-params';
import '../../assets/fonts/tire/tire.scss';
import ContactUsModal from './contact-us-modal';
import DeFlag from './flags/de-flag';
import EnFlag from './flags/en-flag';
import HeFlag from './flags/he-flag';
import Search from './search';

function getFlag(lang: string) {
  switch (lang) {
    case 'en':
      return <EnFlag />;
    case 'de':
      return <DeFlag />;
    case 'he':
      return <HeFlag />;
    default:
      return null;
  }
}

function Header() {
  const { user } = useAppSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const theme = user === null ? 'light' : 'dark';
  const { notification } = App.useApp();
  const location = useLocation();
  const cartEntries = useCartDisplayEntries();

  const { data: notifications, isError } = useNotificationQuery(
    {
      // max signed int 32 length
      limit: 2 ** 31 - 1,
    },
    {
      enabled: user?.__role === 'ROLE_WEBSHOP_USER',
    }
  );

  useEffect(() => {
    if (isError) {
      notification.error({
        key: 'APP005',
        message: (
          <Trans
            i18nKey={'component.header.feedback.notificationFetchError'}
            components={{
              ErrorCode: <Tag className="tw-mr-[length:var(--spacing-1)]" color="error" />,
            }}
          />
        ),
        duration: 0,
      });
    }
  }, [isError, notification]);

  const unreadNotiCount = (notifications ?? []).filter((item) => Boolean(item.isNew)).length;
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const breakpoint = Grid.useBreakpoint();
  const { data: adminSettings } = useAdminSettingQuery();
  const markNotificationAsReadMutation = useMarkNotificationAsReadMutation();
  const [, setSearchParams] = useSearchParams();

  const logoutUser = useCallback(() => {
    setLogoutModalOpen(false);
    Cookies.remove(AUTH_TOKEN_KEY);
    setSearchParams({ action: 'logout' });
    dispatch(
      authStateSlice.actions.logoutUser({
        autoLogout: false,
      })
    );
  }, [dispatch, setSearchParams]);

  useEffect(() => {
    const navLinkClick = () => {
      setMobileMenuOpen(false);
    };

    document.addEventListener('click', (event) => {
      if ((event.target as Element).closest('.mobile-nav-link .nav-link')) {
        navLinkClick();
      }
    });

    return () => {
      document.removeEventListener('click', (event) => {
        if ((event.target as Element).closest('.mobile-nav-link .nav-link')) {
          navLinkClick();
        }
      });
    };
  }, [setMobileMenuOpen]);

  const notificationItemList = useMemo((): ItemType[] => {
    const items = (notifications ?? [])
      .filter((item) => Boolean(item.isNew))
      .slice(0, 3)
      .map((item): ItemType => {
        return {
          type: 'item',
          label: (
            <Link
              to={item.link ? item.link : '#'}
              role="button"
              onClick={(e) => {
                if (!item.link) e.preventDefault();
              }}
              className="noti-link"
            >
              <div className={classNames('noti-item', item.isNew && 'status-new')}>
                <p className={'noti-type'}>{item.notifyRegarding}</p>
                <h5>
                  <span>{item.title}</span>
                  {Boolean(item.isNew) && (
                    <span className={'status'}>{t('page.notifications.new')}</span>
                  )}
                </h5>
                <p className="noti-des">{item.description}</p>
                <small className="date tw-mb-[length:var(--spacing-2)]">
                  {dayjs.unix(item.creationDate).format('LLL')}
                </small>
                <div className="noti-button-group">
                  <Button
                    color="success-outline"
                    size="s"
                    disabled={!item.isNew}
                    onClick={() => {
                      markNotificationAsReadMutation.mutate(item.id, {
                        onError() {
                          notification.error({
                            key: 'NOTI001',
                            message: (
                              <Trans
                                i18nKey="component.header.feedback.failedToMarkAsRead"
                                components={{
                                  ErrorCode: <Tag className="tw-mr-[length:var(--spacing-1)]" color="error" />,
                                }}
                              />
                            ),
                          });
                        },
                      });
                    }}
                  >
                    {t('page.notifications.markAsRead')}
                  </Button>
                  {/* <Button color="error-outline" size="s">
                    Clear
                  </Button> */}
                </div>
              </div>
            </Link>
          ),
          key: `noti-item-${item.id}`,
        };
      });

    return [
      ...(items.length > 0
        ? items
        : ([
            {
              type: 'item',
              label: (
                <div className="noti-item">
                  <p className="tw-mb-0 tw-text-[var(--shade-600)]">
                    {t('component.header.notification.noNewNotification')}
                  </p>
                </div>
              ),
              key: '2',
            },
          ] as ItemType[])),
      {
        type: 'divider',
        key: '3',
      },
      {
        type: 'item',
        label: <Link to="/notifications">{t('component.header.notification.viewAll')}</Link>,
        key: '4',
      },
    ];
  }, [notifications, t, markNotificationAsReadMutation]);

  const leftNavGroupItems = useMemo(() => {
    if (!user || !user.__role) return [];

    return match(user.__role)
      .with('ROLE_WEBSHOP_USER', () => [
        {
          label: t('component.header.leftMenu.dashboard'),
          icon: <IconGrid />,
          url: '/dashboard',
        },
        {
          label: t('component.header.leftMenu.products'),
          icon: <IconBox />,
          url: '/products',
        },
        {
          label: t('component.header.leftMenu.reservedStocks'),
          icon: <IconCalandar />,
          url: '/reserved-stocks',
        },
      ])
      .with('ROLE_ADMIN', () => [
        {
          label: t('component.header.leftMenu.dashboard'),
          icon: <IconGrid />,
          url: DEFAULT_ADMIN_PAGE,
        },
      ])
      .otherwise(() => []);
  }, [user, t]);

  const userDropdownMenuItems = useMemo(() => {
    const menuItems = match(user?.__role)
      .with('ROLE_WEBSHOP_USER', () => [
        {
          label: (
            <Link to="/orders" className="icon-link">
              <IconBox />
              <span>{t('component.header.userMenu.orders')}</span>
            </Link>
          ),
          key: '/orders',
        },
        {
          label: (
            <Link to="/user/notification-settings" className="icon-link">
              <IconGear />
              <span>{t('component.header.userMenu.settings')}</span>
            </Link>
          ),
          key: '/user/notification-settings',
        },
      ])
      .otherwise(() => []);

    return [
      {
        label: (
          <Link to="/user/profile" className="icon-link">
            <IconUser />
            <span>{t('component.header.userMenu.profile')}</span>
          </Link>
        ),
        key: '1',
      },
      ...menuItems,
      {
        type: 'divider',
      },
      {
        label: (
          <button
            className="icon-link tw-bg-transparent tw-border-none tw-cursor-pointer user-account-dropdown-logout-button"
            onClick={() => {
              setLogoutModalOpen(true);
            }}
          >
            <span className="rtl:tw-transform rtl:tw-rotate-180">
              <IconLogout />
            </span>
            <span>{t('component.header.userMenu.logoutBtn')}</span>
          </button>
        ),
        key: '4',
      },
    ];
  }, [user, t]);

  const { Option } = Select;
  const [contactUsModalOpen, setContactUsModalOpen] = useState(false);
  const userSideMenu = useUserSideMenu();
  const [usedVoucher] = useQueryParam('voucher', StringParam);
  // const adminSideMenu =

  return (
    <Fragment>
      {/* {adminSettinFetching ? <Loader fullScreen /> : null} */}
      <Modal
        open={logoutModalOpen}
        title={t('component.header.userMenu.logoutBtn')}
        okText={t('component.header.userMenu.confirmLogoutBtn')}
        cancelText={t('component.header.userMenu.cancelLogoutBtn')}
        onOk={logoutUser}
        onCancel={() => {
          setLogoutModalOpen(false);
        }}
        className="logout-modal"
      >
        {t('component.header.userMenu.logoutConfirmation')}
      </Modal>
      <ContactUsModal open={contactUsModalOpen} onClose={() => setContactUsModalOpen(false)} />
      <nav
        className={classNames(
          `main-header bg-${theme} tw-h-[92px]`,
          theme === 'light' && 'tw-bg-white'
        )}
      >
        <ul className="nav-list tw-mb-0">
          <li className="nav-logo">
            <Link
              className={classNames(
                'navbar-brand main-logo',
                theme === 'dark' &&
                  !adminSettings?.logoLightVariant?.fullpath &&
                  '!tw-bg-white tw-px-[length:var(--spacing-4)] tw-py-[length:var(--spacing-2)]',
                theme === 'light' &&
                  !adminSettings?.logoDarkVariant?.fullpath &&
                  '!tw-bg-black tw-px-[length:var(--spacing-4)] tw-py-[length:var(--spacing-2)]'
              )}
              to="/"
            >
              <img
                src={`${import.meta.env.REACT_APP_API_URL}/var/assets${
                  theme === 'dark'
                    ? (adminSettings?.logoLightVariant?.fullpath ??
                      adminSettings?.logoDarkVariant?.fullpath)
                    : (adminSettings?.logoDarkVariant?.fullpath ??
                      adminSettings?.logoLightVariant?.fullpath)
                }`}
                alt="Tirecloud"
              />
            </Link>
          </li>
          {(user === null ? [] : leftNavGroupItems).map((item, i: number) => (
            <li className="nav-item" key={i}>
              <Tooltip title={breakpoint.xxl ? undefined : item.label} arrow placement="bottom">
                <NavLink className={`nav-link`} to={item.url}>
                  {item.icon}
                  <span className="nav-text" data-text={item.label}>
                    {item.label}
                  </span>
                </NavLink>
              </Tooltip>
            </li>
          ))}
        </ul>
        {user !== null && user.__role === 'ROLE_WEBSHOP_USER' && <Search />}
        <ul className="site-setting tw-mb-0">
          <div className="tw-items-center tw-gap-x-6 !tw-mr-[length:var(--spacing-7)] tw-hidden xxl:tw-flex">
            <li className="nav-item tw-list-none tw-mr-[length:var(--spacing-4)]">
              <Link className="nav-link" to="/about">
                {t('component.header.other.aboutUs')}
              </Link>
            </li>
            <li className="nav-item tw-list-none">
              <Link className="nav-link" to="/brand">
                {t('component.header.other.brand')}
              </Link>
            </li>
            <li className="nav-item tw-list-none">
              <button
                className="nav-link tw-cursor-pointer tw-bg-transparent tw-border-none"
                onClick={() => setContactUsModalOpen(true)}
              >
                {t('component.header.other.contact')}
              </button>
            </li>
          </div>
          <li className="nav-item tw-list-none nav-lang">
            <Tooltip title={t('component.header.other.changeLanguage')} arrow placement="bottom">
              <Select
                className="select-langs"
                placeholder="select one country"
                value={i18n.language}
                onChange={(value) => {
                  i18n.changeLanguage(value);
                }}
                optionLabelProp="label"
                suffixIcon={<IconArrowDown />}
                popupClassName="select-lang-popup"
                data-test-id="header-language-changer"
              >
                {Object.entries(SUPPORTED_LANGUAGES).map(([key, lang]) => {
                  return (
                    <Option
                      aria-label={lang.name}
                      data-option-id={key}
                      value={key}
                      key={key}
                      label={
                        <>
                          <span role="img" aria-label={key}>
                            {getFlag(key)}
                          </span>
                          <span>{key.toUpperCase()}</span>
                        </>
                      }
                    >
                      <Space>
                        <span role="img" aria-label="en">
                          {getFlag(key)}
                        </span>
                        <span>{lang.name}</span>
                      </Space>
                    </Option>
                  );
                })}
              </Select>
            </Tooltip>
          </li>
          {user !== null && (
            <>
              <li className="nav-item">
                <Dropdown
                  overlayClassName="notification-dropdown-over"
                  menu={{ items: notificationItemList }}
                  trigger={['click']}
                >
                  <Tooltip
                    title={t('component.header.other.notification')}
                    arrow
                    placement="bottom"
                  >
                    <Button
                      className="btn-icon badge notification-dropdown-trigger"
                      icon={<IconBell />}
                    >
                      {unreadNotiCount > 0 && <span className="qtylable">{unreadNotiCount}</span>}
                    </Button>
                  </Tooltip>
                </Dropdown>
              </li>
              {user.__role === 'ROLE_WEBSHOP_USER' && (
                <li className="nav-item">
                  <Link className="" to={`/cart${usedVoucher ? `?voucher=${usedVoucher}` : ''}`}>
                    <Tooltip title={t('component.header.other.cart')} arrow placement="bottom">
                      <Button className="btn-icon badge" icon={<IconCart />}>
                        <span className="qtylable">{cartEntries.length ?? 0}</span>
                      </Button>
                    </Tooltip>
                  </Link>
                </li>
              )}
              <li className="nav-item nav-user">
                <Dropdown
                  menu={{ items: userDropdownMenuItems as MenuProps['items'] }}
                  trigger={['click']}
                  overlayClassName="nav-user-popup"
                >
                  <div className="user-setting">
                    {/* <img src={UserImg} className="user-img" /> */}
                    {/* <div className="user-img tw-bg-[var(--shade-600)]" /> */}
                    <div className="user-info">
                      <h5>{user?.about.fullName}</h5>
                      <span>@{user?.username}</span>
                    </div>
                    <IconArrowDown color="#8C887F" />
                  </div>
                </Dropdown>
              </li>
            </>
          )}
          <li className="nav-item tw-list-none nav-hamberger">
            <Button
              onClick={() => setMobileMenuOpen((prev) => !prev)}
              className={classNames('btn-icon', theme === 'light' && '!tw-text-black')}
              icon={<IconHamberger size={24} />}
              aria-label="Mobile Menu Button"
            ></Button>
          </li>
        </ul>
      </nav>

      {breakpoint.xs &&
      user !== null &&
      user.__role === 'ROLE_WEBSHOP_USER' &&
      !location.pathname.startsWith('/orders') ? (
        <Search isMobileSearch={true} />
      ) : null}
      <div className={`mobile-nav-content ${mobileMenuOpen ? 'open' : ''}`}>
        <Button
          onClick={() => setMobileMenuOpen(false)}
          className="close-btn"
          aria-label="Close Mobile Menu Button"
          icon={<IconPlus size={24} />}
        />
        <>
          <ul className="mobile-nav-link">
            {user !== null && (
              <>
                {leftNavGroupItems?.map((item, i: number) => (
                  <li className="nav-item" key={i}>
                    <NavLink className={`nav-link`} to={item.url}>
                      {item.icon}
                      <span className="nav-text" data-text={item.label}>
                        {item.label}
                      </span>
                    </NavLink>
                  </li>
                ))}
                <li className="nav-item">
                  <Link to="/orders" className="nav-link">
                    <IconBox />
                    <span className="nav-text">{t('component.header.userMenu.orders')}</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Collapse
                    expandIcon={() => <IconArrowDown size={24} />}
                    expandIconPosition="end"
                    ghost
                    items={[
                      {
                        key: 1,
                        label: (
                          <a className="nav-link tw-pointer-events-none">
                            <IconUser />
                            <span className="nav-text">{t('component.header.other.account')}</span>
                          </a>
                        ),
                        children: (
                          <ul className="nav-links">
                            {userSideMenu
                              .filter((item) => item.key !== '/user/notification-settings')
                              .map((item) => (
                                <li className="nav-link" key={item.key}>
                                  <Link className="nav-link" to={item.key}>
                                    {item.icon}
                                    <span className="nav-text">{item.label}</span>
                                  </Link>
                                </li>
                              ))}
                          </ul>
                        ),
                      },
                    ]}
                  />
                </li>
                <li className="nav-item">
                  <Link to="/user/notification-settings" className="nav-link">
                    <span>
                      <IconGear size={20} />
                    </span>
                    <span className="nav-text">
                      {t('component.header.userMenu.notificationSettings')}
                    </span>
                  </Link>
                </li>
              </>
            )}
            <li className="nav-item">
              <Collapse
                expandIcon={() => <IconArrowDown size={24} />}
                expandIconPosition="end"
                ghost
                className="nav-resource-collapse"
                items={[
                  {
                    key: 1,
                    label: (
                      <a className="nav-link tw-pointer-events-none">
                        <IconUser />
                        <span className="nav-text">{t('component.header.other.resources')}</span>
                      </a>
                    ),
                    children: (
                      <ul className="nav-links">
                        <li className="nav-link">
                          <Link className="nav-link" to="/about">
                            <IconExclamation />
                            <span className="nav-text">{t('component.header.other.aboutUs')}</span>
                          </Link>
                        </li>
                        <li className="nav-link">
                          <Link className="nav-link" to="/brand">
                            <IconExclamation />
                            <span className="nav-text">{t('component.header.other.brand')}</span>
                          </Link>
                        </li>
                        <li className="nav-link">
                          <button
                            className="nav-link tw-cursor-pointer tw-bg-transparent tw-border-none"
                            onClick={() => setContactUsModalOpen(true)}
                          >
                            <PhoneOutlined />
                            <span className="nav-text">{t('component.header.other.contact')}</span>
                          </button>
                        </li>
                      </ul>
                    ),
                  },
                ]}
              />
            </li>
            {user !== null && (
              <li className="nav-item">
                <button
                  className="nav-link tw-bg-transparent tw-border-none"
                  onClick={() => {
                    setMobileMenuOpen(false);
                    setLogoutModalOpen(true);
                  }}
                >
                  <span className="rtl:tw-transform rtl:tw-rotate-180">
                    <IconLogout />
                  </span>
                  <span className="nav-text">{t('component.header.userMenu.logoutBtn')}</span>
                </button>
              </li>
            )}
          </ul>
        </>
        <ul className="mobile-lang">
          {Object.entries(SUPPORTED_LANGUAGES).map(([key, lang]) => {
            return (
              <li key={key}>
                <Button
                  color={`${i18n.language == key ? 'primary' : 'secondary'}`}
                  onClick={() => {
                    setMobileMenuOpen(false);
                    i18n.changeLanguage(key);
                  }}
                  aria-label={lang.name}
                  className={classNames(i18n.language === key && '!tw-text-black')}
                >
                  {getFlag(key)}
                  <span>{lang.shortCode.toUpperCase()}</span>
                </Button>
              </li>
            );
          })}
        </ul>
        <div className="tw-mt-auto">
          <Link
            to="/privacy-policy"
            className="tw-mr-[length:var(--spacing-4)] tw-text-[var(--shade-400)] hover:tw-text-[var(--shade-400)]"
          >
            {t('component.footer.privacyPolicy')}
          </Link>
        </div>
      </div>
    </Fragment>
  );
}

export default Header;
