import { AdminSettings } from '@/utils/misc';
import patchFeatureFlag from './feature-flags';

export function kislevPatchAdminSettings(
  originalAdminSettings: AdminSettings,

  response: any
): AdminSettings {
  const adminSettings = response.adminSettings[0];

  return {
    ...originalAdminSettings,
    logoLightVariant: null,
    logoDarkVariant: adminSettings.desktopLogo,
    featureFlags: patchFeatureFlag(originalAdminSettings.featureFlags),
  };
}


