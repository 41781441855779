import useSwVersion from '@/useSwVersion';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Footer() {
  const { t } = useTranslation();
  const { version } = useSwVersion();

  return (
    <footer className="tw-h-[50px] tw-bg-black tw-text-white">
      <div className="tw-px-[var(--spacing-4)] tw-h-full">
        <div className="tw-flex tw-justify-between tw-items-center tw-h-full tw-py-[length:var(--spacing-4)]">
          <div className="version" dir="ltr">
            {['live', 'main', 'master'].includes(import.meta.env.REACT_APP_BRANCH as string)
              ? version.split('-')[0].split('#')[0]
              : version}
          </div>
          <div className="tw-inline-flex tw-items-center tw-gap-x-2">
            <div>
              <Link
                to="/privacy-policy"
                className="tw-text-[var(--shade-400)] hover:tw-text-[var(--shade-400)] tw-hidden md:tw-block"
              >
                {t('component.footer.privacyPolicy')}
              </Link>
            </div>
            <Divider
              type="vertical"
              className="tw-border-[var(--shade-400)] tw-hidden md:tw-block"
            />
            <div className="copyright">
              {t('component.footer.copyright', {
                year: new Date().getFullYear(),
              })}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
