import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import { Button } from 'tirecloud-pattern-library/dist/components/button/Button';
import css from './index.module.scss';

function GlobalErrorElement() {
  const { t } = useTranslation();
  const error = useRouteError();

  return (
    <main className="page-content">
      <div className={css['error']}>
        <div className={css.inner}>
          <h1 dangerouslySetInnerHTML={{ __html: t('page.errors.globalCatchAllError.code') }}></h1>
          <h3>{t('page.errors.globalCatchAllError.description')}</h3>
          {error !== undefined && error !== null ? (
            <p className="tw-mb-[var(--spacing-8)]">{error.toString()}</p>
          ) : null}
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('page.errors.globalCatchAllError.reloadBtn')}
          </Button>
        </div>
      </div>
    </main>
  );
}

export function GenericErrorComponent({
  errorCode,
  errorMessage,
  errorDetails,
  actionButton,
}: {
  errorCode: ReactNode;
  errorMessage: ReactNode;
  errorDetails?: ReactNode;
  actionButton: ReactNode;
}) {
  return (
    <main className="page-content">
      <div className={css['error']}>
        <div className={css.inner}>
          <h1>{errorCode}</h1>
          <h3>{errorMessage}</h3>
          {errorDetails !== undefined && errorDetails !== null ? (
            <p className="tw-mb-[var(--spacing-8)]">{errorDetails}</p>
          ) : null}
          {actionButton}
        </div>
      </div>
    </main>
  );
}

export default GlobalErrorElement;
