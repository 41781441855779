import { BaseFeatureFlags } from '@/utils/feature-flags';

const env = import.meta.env ?? process.env;

export default function patchFeatureFlag(originalFeatureFlags: BaseFeatureFlags): BaseFeatureFlags {
  return {
    ...originalFeatureFlags,
    page: {
      ...originalFeatureFlags.page,
      mockup: {
        enabled: false,
      },
      product: {
        ...originalFeatureFlags.page.product,
        // enableProductBundle: !['live', 'main', 'master'].includes(env.REACT_APP_BRANCH),
        enableProductBundle: true,
        enableVoucherSlider: false,
        enableTireDot: false,
        enableDirectInquiry: false,
        enableOEAttributeValueBox: true,
      },
      dashboard: {
        ...originalFeatureFlags.page.dashboard,
        useExperimentalDashboardSetting: false,
        enableVoucherSlider: false,
      },
      cart: {
        enableVoucherSelector: false,
      },
      misc: {
        ...originalFeatureFlags.page.misc,
        enableRefreshToken: true,
      },
      admin: {
        ...originalFeatureFlags.page.admin,
        analytics: {
          enabled: false,
        },
        dashboardSettings: {
          enabled: false,
        },
        emailSettings: {
          enabled: false,
        },
      },
    },
    component: {
      ...originalFeatureFlags.component,
      checkout: {
        ...originalFeatureFlags.component.checkout,
        enableDeliveryOption: false,
        enableCheckoutStepsInProduct: false,
      },
      productSearch: {
        ...originalFeatureFlags.component.productSearch,
        enableSuggestion: true,
      }
    },
  };
}
