import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';
import {
  ACTIVE_VERSION_KEY,
  APP_UPDATE_MODAL_REMIND_ME_LATER_KEY,
  APP_VERSION,
  DB_NAME,
  STORE_NAME,
} from './constants';

// Function to read version from IndexedDB
async function readVersionFromDB(key: string): Promise<string | null> {
  return new Promise((resolve, reject) => {
    if (!('indexedDB' in window)) {
      return reject(new Error('IndexedDB is not supported'));
    }

    const request = indexedDB.open(DB_NAME, 1);

    request.onerror = () => {
      console.error('Error opening IndexedDB:', request.error);
      reject(request.error);
    };

    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction(STORE_NAME, 'readonly');
      const store = transaction.objectStore(STORE_NAME);
      const getRequest = store.get(key);

      getRequest.onerror = () => {
        console.error('Error reading version:', getRequest.error);
        reject(getRequest.error);
      };

      getRequest.onsuccess = () => {
        resolve(getRequest.result || null);
      };
      transaction.oncomplete = () => db.close();
    };

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    };
  });
}

export default function useSwVersion() {
  const [version, setVersion] = useState('');

  const {
    offlineReady: [, setOfflineReady],
    needRefresh: [swNeedRefresh, setSwNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered(r) {
      if (r?.active?.scriptURL) {
        console.info('SW Registered: ' + r?.active?.scriptURL);
      } else {
        console.info('SW Registered');
      }
    },
    onRegisterError(error) {
      console.error('SW registration error: ', error);
      Sentry.captureException(error);
    },
  });

  // Read version from IndexedDB
  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const activeVersion = await readVersionFromDB(ACTIVE_VERSION_KEY);
        if (activeVersion) {
          setVersion(activeVersion);
        } else {
          setVersion(APP_VERSION); // Fallback to current version if not found in DB
        }
      } catch (error) {
        console.error('Failed to read version from IndexedDB:', error);
        Sentry.captureException(error);
        setVersion(APP_VERSION); // Fallback to current version on error
      }
    };

    fetchVersion();
  }, [swNeedRefresh]); // Re-run when SW needs refresh

  const close = useCallback(() => {
    setOfflineReady(false);
    setSwNeedRefresh(false);
    localStorage.setItem(
      APP_UPDATE_MODAL_REMIND_ME_LATER_KEY,
      dayjs().add(1, 'minute').unix().toString()
    );
  }, [setSwNeedRefresh, setOfflineReady]);

  const updateApp = useCallback(async () => {
    await updateServiceWorker(true);
    setSwNeedRefresh(false);
  }, [setSwNeedRefresh, updateServiceWorker]);

  const shouldShow = useMemo(() => {
    const reminderMeLaterTime = localStorage.getItem(APP_UPDATE_MODAL_REMIND_ME_LATER_KEY);
    return (
      swNeedRefresh &&
      (!reminderMeLaterTime || dayjs().isAfter(dayjs.unix(parseInt(reminderMeLaterTime))))
    );
  }, [swNeedRefresh]);

  return {
    version,
    close,
    shouldShow,
    updateApp,
  };
}
