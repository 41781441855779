import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { Button } from 'tirecloud-pattern-library';
import { Modal } from 'tirecloud-pattern-library/dist/components/modal/Modal';
import type useSwVersion from './useSwVersion';

const AppUpdateModal = ({ shouldShow, close, updateApp }: ReturnType<typeof useSwVersion>) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={shouldShow}
        title={t('component.appUpdateModal.title')}
        centered
        zIndex={5000}
        closable={false}
        footer={
          <div
            key="footer-buttons"
            className="tw-flex tw-justify-between tw-items-center tw-gap-x-2"
          >
            <div className="tw-flex tw-items-center tw-gap-x-2">
              <Button key="cancel" color="secondary" onClick={close}>
                {t('component.appUpdateModal.remindMeLater')}
              </Button>
            </div>
            <Button
              key="ok"
              onClick={() => {
                try {
                  updateApp()
                    .then(() => {
                      window.location.reload();
                    })
                    .catch((error) => {
                      console.error('SW update error: ', error);
                      Sentry.captureException(error);
                      window.location.reload();
                    });
                } catch (error) {
                  console.log('Something wrong while calling updateServiceWorker function', error);
                  Sentry.captureException(error);
                  window.location.reload();
                }
              }}
            >
              {t('component.appUpdateModal.updateNow')}
            </Button>
          </div>
        }
      >
        {t('component.appUpdateModal.description')}
      </Modal>
    </>
  );
};

export default AppUpdateModal;
